<template>
    <div>
       <footer class="text-center text-lg-start border-top bg-light text-muted fixed-bottom">
      <!-- Section: Social media -->
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
         
        </div>
        <!-- Left -->
    <span class="iconos">Kubo Contact © Development</span>
        <!-- Right -->
        <div>
          <a href="" class="me-4 link-secondary">
            <i class="fab fa-facebook-f  iconos"></i>
          </a>
          <a href="" class="me-4 link-secondary">
            <i class="fab fa-twitter iconos"></i>
          </a>
          <a href="" class="me-4 link-secondary">
            <i class="fab fa-google iconos"></i>
          </a>
          <a href="" class="me-4 link-secondary">
            <i class="fab fa-instagram iconos"></i>
          </a>
          <a href="" class="me-4 link-secondary">
            <i class="fab fa-linkedin iconos"></i>
          </a>
          <a href="" class="me-4 link-secondary">
            <i class="fab fa-github iconos"></i>
          </a>
        </div>
        <!-- Right -->
      </section>
  </footer>
  </div>
  </template>