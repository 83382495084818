import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      token: localStorage.getItem('token') || '',
      client : "load"
    };
  },
  getters: {
    getToken: state => state.token,
    getTClient: state => state.client,
  },
  mutations: {
    setToken(state, token){
      state.token = token;
    },
    setClient(state, client){
      state.client = client;
    }
   
  },
  actions: {
    updateToken({ commit}, token){
      commit('setToken',token);
      
    },
    updateClient({ commit}, client){
      commit('setClient',client);
      
    }
  },
  modules: {
  }
})
