
import { reactive } from 'vue';

const config = reactive({
    APP_DEV: false,
    API_URL_DEV: "http://localhost:4000/api",
    API_URL_PROD: "https://service.whaxia.com/api",
    //API_URL_PROD: "https://send.whaxia.com/api",
    WEBSOCKET_PROD:"wss://service.whaxia.com/socket",
    //WEBSOCKET_PROD:"wss://send.whaxia.com/socket",
    WEBSOCKET_DEV:"ws://localhost:3000",
  // Otras configuraciones que desees...
});

export default config;
