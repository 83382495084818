<template>
  <div>
    <router-view/>
    <Footer />
  </div>


</template>

<script>
import Footer from '@/components/Footer';
//import PruebaWebPhone from '@/components/PruebaWebPhone';
//import  '@/assets/js/custom.js'
import Message from "./services/Message";

export default {
  components: {
    Footer
  },
  data() {
    return {
      app: true,
      nro_telefono: window.localStorage.getItem("nro_telefono"),
      id: window.localStorage.getItem("id")
    };
  },
  methods: {
    // Métodos de tu componente, si es necesario
  },
  created() {

    const data = {
        nro_telefono: this.nro_telefono,
        id: this.id
      };
      
      if(this.nro_telefono && this.id){
        //console.log(data);

      Message.instance(data).then(response => {
        response;      

      }).catch((error) => {
        localStorage.clear();
        this.$router.push('/login');
        error;
        //console.log(error);
      })
      }else{
        this.$router.push('/login');
      }

      
  }
};


</script>
<style>

@import "./assets/css/style.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
